import React, { useState } from "react";
import ripvideo from "../assets/ripley.gif";
import Loader from "./Loader";

function Video() {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  return (
    <div>
      {!loaded && <Loader />}
      <img
        src={ripvideo}
        alt="ripvideo"
        style={{ display: loaded ? "block" : "none" }}
        onLoad={handleImageLoad}
      />
    </div>
  );
}

export default Video;
