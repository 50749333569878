// RouterComponent.js
import React from "react";
import Home from "./Home.js";
import Comic from "./Comic";
import MentalAF from "./MentalAF";
import Marvin from "./Marvin";
import Orange from "./BurnNFT.tsx";
import ComicReader from "./ComicReader";
import ComicReaderWeb from "./ComicReaderWeb";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./Navbar";
import "./Styles.css";
import Swapper from "./swapper.tsx";
import TraitShop from "./trait_shop.tsx";
import Specials from "./special_trait_benefits.tsx";
import Mymarvins from "./Mymarvins";
import Footer from "./Footer.js";
import Contact from "./Contact.js";
import About from "./About.js";
import Kiosk from "./Kiosk.js";
import Terms from "./TermsConditions.js";

const RouterComponent = () => {
  return (
    <Router>
      <div>
        <div className="fixed">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/marvin/*" element={<Marvin />}>
              <Route path="mymarvins" element={<Mymarvins />} />
              <Route path="swapper" element={<Swapper />} />
              <Route path="traitshop" element={<TraitShop />} />
              <Route path="specials" element={<Specials />} />
            </Route>

            <Route path="/orange" element={<Orange />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/mentalaf" element={<MentalAF />} />
            <Route path="/comic" element={<Comic />} />
            <Route path="/kiosk/*" element={<Kiosk />} />
            <Route path="/reader1" element={<ComicReader />} />
            <Route path="/reader2" element={<ComicReaderWeb />} />
          </Routes>
        </div>
        <Navbar />
        <Footer />
        <ToastContainer
          toastContainerClassName="custom-toast-container"
          pauseOnFocusLoss={false}
          closeOnClick
          draggable
          pauseOnHover={false}
          position="bottom-right"
          rtl={false}
          hideProgressBar={false}
          autoClose={3500}
          newestOnTop={true}
          toastStyle={{ backgroundColor: "#9DF6FB", color: "black" }}
        />
      </div>
    </Router>
  );
};

export default RouterComponent;
