import { useEffect, useState } from "react";
import algosdk from "algosdk";
import { getBalance, swap_request_tx } from "./utilities.ts";
import { getAssetsFromAddress } from "./utilities.ts";
import { SelectField } from "../components/select_field.tsx";
import {
  ALGO_FEE,
  CREATOR_WALLET,
  CUBE_FEE,
  FULL_MENTAL_MARVIN_RESERVE,
  NODE_ENDPOINT,
  TRAITS,
} from "../constants.ts";
import { incompatibleTraitRules } from "../TraitClash";
import AssetBoxForSwap from "../components/asset_box_for_swap.tsx";
import algo_logo from "../assets/algo.webp";
import cube_icon from "../assets/cube.webp";
import { toast } from "react-toastify";
import axios from "axios";
import { API_ENDPOINT } from "../constants.ts";
import { decodeSignedTransaction, decodeUnsignedTransaction } from "algosdk";
import { encode, decode } from "uint8-to-base64";
import React from "react";
import { useWallet } from "@txnlab/use-wallet";
import "./swapper.css";
import TraitBox from "./traitbox.tsx";
import marvin1 from "../assets/marvin/marvin1.webp";
import marvin3 from "../assets/marvin/marvin3.webp";

export default function Swapper() {
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [connectType, setWalletType] = useState("");
  const [loading, setLoading] = useState(true);

  const [userAssets, setUserAssets] = useState([] as number[]);
  const [userBalance, setUserBalance] = useState([0, 0]);

  const [firstAsset, setFirstAsset] = useState("");
  const [secondAsset, setSecondAsset] = useState("");
  const [selectedTraits, setSelectedTraits] = useState<string[]>([]);

  const [isClicked, setIsClicked] = useState(false);
  const [transactions, setTransactions] = useState([] as any[]);
  const { activeAccount, signTransactions } = useWallet();

  const [showToast, setShowToast] = useState(false);
  const [isSwapButtonClickable, setIsSwapButtonClickable] = useState(false);
  const [trait1Data, setTrait1Data] = useState([]);
  const [trait2Data, setTrait2Data] = useState([]);
  const [incompatibleTraits, setIncompatibleTraits] = useState<string[]>([]);

  useEffect(() => {
    // Check for incompatible traits
    if (trait1Data && trait2Data) {
      const newIncompatibleTraits: string[] = [];

      for (const { trait1, value1, trait2, value2 } of incompatibleTraitRules) {
        const trait1Value1Matches = Array.isArray(value1)
          ? value1.includes(trait1Data[trait1])
          : typeof value1 === "function"
          ? value1(trait1Data[trait1])
          : trait1Data[trait1] === value1;

        const trait2Value2Matches = Array.isArray(value2)
          ? value2.includes(trait2Data[trait2])
          : typeof value2 === "function"
          ? value2(trait2Data[trait2])
          : trait2Data[trait2] === value2;

        const trait2Value1Matches = Array.isArray(value1)
          ? value1.includes(trait2Data[trait1])
          : typeof value1 === "function"
          ? value1(trait2Data[trait1])
          : trait2Data[trait1] === value1;

        const trait1Value2Matches = Array.isArray(value2)
          ? value2.includes(trait1Data[trait2])
          : typeof value2 === "function"
          ? value2(trait1Data[trait2])
          : trait1Data[trait2] === value2;

        if (
          (trait1Value1Matches && trait2Value2Matches) ||
          (trait2Value1Matches && trait1Value2Matches)
        ) {
          newIncompatibleTraits.push(trait1.toString(), trait2.toString());
        }
      }

      setIncompatibleTraits(newIncompatibleTraits);
    }
  }, [trait1Data, trait2Data]);

  useEffect(() => {
    // Update the clickability status whenever relevant conditions change
    setIsSwapButtonClickable(
      firstAsset !== "" &&
        secondAsset !== "" &&
        firstAsset !== secondAsset &&
        selectedTraits.length > 0
    );
  }, [firstAsset, secondAsset, selectedTraits]);

  // Function to handle the toast being closed
  const handleToastClose = () => {
    setShowToast(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userAddressLocal = activeAccount?.address;
        const connectTypeLocal = activeAccount?.providerId;

        if (userAddressLocal && connectTypeLocal) {
          setWalletAddress(userAddressLocal);
          setWalletType(connectTypeLocal);
        }
      } catch (error) {
        console.error("Error fetching account information:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [activeAccount]);

  useEffect(() => {
    // Checking if the walletAddress is not an empty string before making API calls
    if (walletAddress !== "" && walletAddress !== "0") {
      // Fetching assets associated with the provided walletAddress
      getAssetsFromAddress(walletAddress).then((res) => {
        // Logging the content of the response (res) to the console
        // console.log("Fetched user assets:", res);
        // Updating the state with the fetched user assets
        setUserAssets(res);

        // Fetching the balance associated with the provided walletAddress
        getBalance(walletAddress).then((res) => {
          // Updating the state with the fetched user balance
          setUserBalance(res);

          // Setting loading state to false, indicating that the data has been successfully fetched
          setLoading(false);
        });
      });
    }
  }, [walletAddress]); // The effect will re-run if the walletAddress dependency changes

  /*  if (loading) {
      return (
        <div className="flex flex-col justify-center items-center text-primary-green font-thin">
      <img
        className="aspect-square max-h-48"
        src={loadingGIF}
        alt="loading"
      />
      Loading...
        </div>
      );
    } */

  async function checkSwap() {
    if (firstAsset === "" || secondAsset === "") {
      toast.info("Please select two Marvins to swap!");
      return;
    }
    if (firstAsset === secondAsset) {
      toast.info("Please select different Marvins to swap!");
      return;
    }
    if (selectedTraits.length === 0) {
      toast.info("Please select at least one trait to swap!");
      return;
    }
    setIsClicked(true);

    try {
      const first_asset_reserve_response = await axios.get(
        `${NODE_ENDPOINT}/v2/assets/${firstAsset}`
      );
      if (
        first_asset_reserve_response.data.params.reserve ===
        FULL_MENTAL_MARVIN_RESERVE
      ) {
        toast.error("You can't swap a full mental Marvin!");
        setIsClicked(false);
        return;
      }

      const second_asset_reserve_response = await axios.get(
        `${NODE_ENDPOINT}/v2/assets/${secondAsset}`
      );
      if (
        second_asset_reserve_response.data.params.reserve ===
        FULL_MENTAL_MARVIN_RESERVE
      ) {
        toast.error("You can't swap a full mental Marvin!");
        setIsClicked(false);
        return;
      }

      const rebalance = await getBalance(walletAddress);
      setUserBalance(rebalance);
      if (userBalance[0] < selectedTraits.length * ALGO_FEE) {
        toast.error("You don't have enough ALGO to pay for the swap fee!");
        setIsClicked(false);
        return;
      }
      if (userBalance[1] < selectedTraits.length * CUBE_FEE) {
        toast.error("You don't have enough FC to pay for the swap fee!");
        setIsClicked(false);
        return;
      }

      const transaction = await swap_request_tx(
        walletAddress,
        parseInt(firstAsset),
        parseInt(secondAsset),
        selectedTraits
      );

      if (!transaction) {
        toast.error("Something went wrong while creating the swap request!");
        setIsClicked(false);
        return;
      }

      // Convert algosdk.Tx to Uint8Array
      const encodedTransaction = algosdk.encodeUnsignedTransaction(transaction);

      // Sign transactions using useWallet module
      const signedTransactions = await signTransactions([encodedTransaction]);

      // Extract signed transaction
      const signedTxn = signedTransactions[0];

      // Encode the signed transaction
      const encodedTxn = encode(signedTxn);

      if (encodedTxn) {
        toast.info("Please wait while creating the swap transactions...");
        const res = await axios.post(`${API_ENDPOINT}/swap/`, {
          tx: encodedTxn,
        });

        if (res.status === 200) {
          const transactions = res.data.transactions;
          if (transactions.length !== 4) {
            console.error("Unexpected number of transactions:", transactions);
            toast.error("Something went wrong while creating the swap!");
            setIsClicked(false);
            return;
          }
          setIsClicked(false);
          toast.info("Please sign the transactions to complete the swap!");
          setTransactions(transactions);
        }
      } else {
        toast.error("Something went wrong while creating the swap!");
        setIsClicked(false);
      }
    } catch (error: any) {
      console.error("Error during checkSwap:", error);
      if (error.response) {
        console.error("Response from the server:", error.response.data);
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsClicked(false);
    }
  }

  async function handleSwap() {
    if (!transactions || transactions.length !== 4) {
      toast.error("Something went wrong while creating the swap!");
      return;
    }
    setIsClicked(true);
    try {
      let user_algo_payment_txn = decodeUnsignedTransaction(
        Buffer.from(transactions[0], "base64")
      );
      let user_cube_payment_txn = decodeUnsignedTransaction(
        Buffer.from(transactions[1], "base64")
      );
      let first_asset_config_txn = decodeUnsignedTransaction(
        Buffer.from(transactions[2], "base64")
      );
      let second_asset_config_txn = decodeSignedTransaction(
        Buffer.from(transactions[3], "base64")
      );
      let txnsToValidate = [] as Uint8Array[];
      toast.info("Please sign the transactions to complete the swap...");

      const multipleTxnGroups = [
        { txn: user_algo_payment_txn, signers: [walletAddress] },
        { txn: user_cube_payment_txn, signers: [walletAddress] },
        { txn: first_asset_config_txn, signers: [CREATOR_WALLET] },
        { txn: second_asset_config_txn.txn, signers: [CREATOR_WALLET] },
      ];

      // Flatten the array of transaction groups into a single array of transactions
      const transactionsToSign = multipleTxnGroups
        .map(({ txn }) => algosdk.encodeUnsignedTransaction(txn))
        .flat();

      // Sign transactions using useWallet module
      const signedTransactions = await signTransactions([transactionsToSign]);

      if (!signedTransactions) {
        toast.error("Something went wrong while signing the transactions!");
        setIsClicked(false);
        return;
      }

      txnsToValidate = [
        signedTransactions[0],
        signedTransactions[1],
        decode(transactions[2]),
        decode(transactions[3]),
      ];
      // Encode the signed transactions
      const validateData = txnsToValidate.map((txn) => {
        return encode(txn);
      });

      // Log the request payload
      console.log("Request Payload:", JSON.stringify({ txns: validateData }));

      // Validate the signed transactions against your API
      const validate_response = await axios.post(
        `${API_ENDPOINT}/swap/validate/`,
        {
          txns: validateData,
        }
      );
      console.log("Validate Response:", validate_response);

      if (validate_response.status === 200) {
        toast.success("Swap successfully completed!");

        setIsClicked(false);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error("Something went wrong while validating the transactions!");
        setIsClicked(false);
      }
    } catch (error: any) {
      console.error("Error during handleSwap:", error);
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsClicked(false);
    }
  }

  return (
    <div className="limiter">
      <main className="swappermain">
        <h2>SWAPPER</h2>
        {userAssets.length === 0 ? (
          <div className="nowalletswapper">
            <p></p>
            <img src={marvin1} alt="Mental Marvin" className="marvinimg1" />
            <h4>
              Can this be? You have no Marvins! You need at least 2 to swap.
            </h4>
            <h5>
              Have no fear you can always visit these aggregators to grab some.
            </h5>
            <div className="marketwrapper">
              <a
                href="https://www.minthol.art/3%3A0_1643/assets/all?listingTypes=BUY&listingTypes=BID"
                target="blank"
                className="marketlink"
              >
                <img src="/minthol.jpg" alt="minthol.art" /> MINTHOL
              </a>
              <a
                href="https://www.asalytic.app/collection/mental-marvin?tab=market&traitTypes=&traitValues=&filterTypes=&filterValues="
                target="blank"
                className="marketlink"
              >
                <img src="/asalytic.png" alt="asalytic.app" /> ASALYTIC
              </a>
            </div>
          </div>
        ) : (
          <>
            {userAssets.length < 2 ? (
              <p>You need at least 2 Marvins to swap!</p>
            ) : (
              <div>
                <div className="swapdescription">
                  <p>
                    All Mental Marvin's are unique and trait swapper will not
                    allow to create a copy of another Mental Marvin. <br />
                    Mental Marvin home page always has the correct trait and
                    image data.
                  </p>
                </div>
                <div className="maincontainer">
                  <div className="Selectfield SelectField--left">
                    <SelectField
                      text="First Marvin"
                      selectedAsset={firstAsset}
                      setSelectedAsset={setFirstAsset}
                      assets={userAssets}
                    />
                    {firstAsset !== "" ? (
                      <div className="swapperimage">
                        <AssetBoxForSwap
                          asset_id={firstAsset}
                          onNameReceived={undefined}
                          onTraitsReceived={undefined}
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="swapperimage">
                          <img src={marvin3} alt="marvin" className="marvin3" />
                        </div>
                      </div>
                    )}
                    <div className="traits1">
                      {firstAsset !== "" ? (
                        <TraitBox
                          asset_id={firstAsset} // @ts-ignore
                          onTraitsReceived={setTrait1Data}
                        />
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                  {/* TRAIT PICKER*/}
                  <div className="middlsection">
                    <div className="selecttraits">
                      <p className="select-traits-header">
                        Select Traits to Swap
                      </p>
                      <ul className="listitems">
                        {TRAITS.map((trait) => (
                          <li
                            key={trait}
                            onClick={(e) => {
                              if (!incompatibleTraits.includes(trait)) {
                                if (selectedTraits.includes(trait)) {
                                  setSelectedTraits((prev) =>
                                    prev.filter((t) => t !== trait)
                                  );
                                } else {
                                  setSelectedTraits((prev) => [...prev, trait]);
                                }
                              }
                            }}
                          >
                            <label
                              className={`trait-label ${
                                selectedTraits.includes(trait) ? "selected" : ""
                              }`}
                            >
                              <div
                                className={`trait-image ${
                                  incompatibleTraits.includes(trait)
                                    ? "incompatible"
                                    : ""
                                }`}
                              >
                                <img
                                  src={`/traits/${trait}.webp`}
                                  alt={trait}
                                  className={
                                    selectedTraits.includes(trait)
                                      ? "selected"
                                      : ""
                                  }
                                />
                                {incompatibleTraits.includes(trait) && (
                                  <div className="trait-overlay"></div>
                                )}
                              </div>
                              <span className="trait-name">{trait}</span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {/* SWAP COST */}
                    <div className="swapcost">
                      <p>COST</p>
                      <div className="costmobile">
                        <div className="priceshow">
                          <div className="algo">
                            <img src={algo_logo} alt="algo" />
                            {selectedTraits.length * ALGO_FEE}
                          </div>
                          <div className="fccube">
                            <img src={cube_icon} alt="cube" />
                            {selectedTraits.length * CUBE_FEE}
                          </div>
                        </div>
                        {isClicked ? (
                          <button
                            className="btn btn-info"
                            type="button"
                            disabled
                          >
                            <div
                              className="spinner-border text-light mr-2"
                              role="status"
                            ></div>
                          </button>
                        ) : (
                          <>
                            {transactions.length === 4 ? (
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={handleSwap}
                                disabled={!isSwapButtonClickable}
                              >
                                SWAP
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-info"
                                onClick={checkSwap}
                                disabled={!isSwapButtonClickable}
                              >
                                CHECK
                              </button>
                            )}
                          </>
                        )}
                      </div>
                      <p className="text-primary-white/80 text-xs text-center mt-1">
                        Swap process consists of two steps.
                      </p>
                    </div>
                  </div>
                  {/* MARVIN ON THE RIGHT */}
                  <div className="Selectfield SelectField--right">
                    <SelectField
                      text="Second Marvin"
                      selectedAsset={secondAsset}
                      setSelectedAsset={setSecondAsset}
                      assets={userAssets}
                    />
                    {secondAsset !== "" ? (
                      <div className="swapperimage">
                        <AssetBoxForSwap
                          asset_id={secondAsset}
                          onNameReceived={undefined}
                          onTraitsReceived={undefined}
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="swapperimage">
                          <img src={marvin3} alt="marvin" className="marvin3" />
                        </div>
                      </div>
                    )}
                    <div className="traits2">
                      {secondAsset !== "" ? (
                        <TraitBox
                          asset_id={secondAsset} // @ts-ignore
                          onTraitsReceived={setTrait2Data}
                        />
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                </div>
                {firstAsset === secondAsset && firstAsset !== "" && (
                  <p className="text-primary-red text-center text-lg font-semibold pt-4 animate-pulse">
                    Please select different Marvins to swap!
                  </p>
                )}
              </div>
            )}
          </>
        )}
      </main>
    </div>
  );
}
