import React, { useState } from "react";
import { PDFDocument } from "pdf-lib";
import QRCode from "qrcode";
import pdfCommon from "../assets/comic/pdf1.pdf";
import pdfUncommon from "../assets/comic/pdf2.pdf";
import pdfRare from "../assets/comic/pdf3.pdf";
import pdfEpic from "../assets/comic/pdf4.pdf";
import pdfLegendary from "../assets/comic/pdf5.pdf";
import { toast } from "react-toastify";

const PdfWithQrCode = ({ asset, isOpened, rarity }) => {
  const [loading, setLoading] = useState(false);

  // Function to select the correct PDF based on rarity
  const selectPdfFile = () => {
    switch (rarity) {
      case "common":
        return pdfCommon;
      case "uncommon":
        return pdfUncommon;
      case "rare":
        return pdfRare;
      case "epic":
        return pdfEpic;
      case "legendary":
        return pdfLegendary;
      default:
        return pdfCommon; // Fallback to a default PDF if no match is found
    }
  };

  // Function to generate QR code Data URL
  const generateQrCodeUrl = () => {
    return new Promise((resolve, reject) => {
      QRCode.toDataURL(
        `https://allo.info/asset/${asset}`,
        {
          width: 200, // QR Code dimensions
          margin: 1,
        },
        (err, url) => {
          if (err) {
            reject("Failed to generate QR code");
          } else {
            resolve(url);
          }
        }
      );
    });
  };

  // Function to generate the PDF with QR code
  const handleGeneratePdf = async () => {
    if (!asset) {
      toast.error("No asset selected. Please select an asset first.");
      return;
    }

    // Check if the comic is opened
    if (!isOpened) {
      toast.error("Please open the comic first before downloading the PDF.");
      return;
    }

    setLoading(true);
    try {
      toast.info("Grabbing a LQ PDF of your comic");
      // Generate QR code Data URL
      const qrCodeUrl = await generateQrCodeUrl();

      // Fetch the PDF file
      const pdfFile = selectPdfFile();
      const pdfBytes = await fetch(pdfFile).then((res) => res.arrayBuffer());

      // Load the PDF
      const pdfDoc = await PDFDocument.load(pdfBytes);

      // Get the last page of the PDF
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      const { width, height } = firstPage.getSize();

      const lastPage = pages[pages.length - 1]; // Last page

      // Embed the QR code image into the PDF
      const qrCodePng = await pdfDoc.embedPng(qrCodeUrl);

      // Adjust QR code positioning and size
      const qrCodeWidth = 50; // QR Code width in pixels
      const qrCodeHeight = 50; // QR Code height in pixels
      lastPage.drawImage(qrCodePng, {
        x: width / 2 - qrCodeWidth / 2, // Center horizontally
        y: 30, // Position from the bottom
        width: qrCodeWidth,
        height: qrCodeHeight,
      });

      // Save the PDF with the QR code
      const pdfBytesWithQrCode = await pdfDoc.save();

      // Trigger PDF download
      const blob = new Blob([pdfBytesWithQrCode], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `MM_FORK_${asset}.pdf`;
      link.click();
    } catch (error) {
      toast.info("Something went wrong. OH MY! ");
      console.error("Error generating PDF with QR code:", error);
    } finally {
      toast.info("We did it! It's a big file. SENDING! ");
      setLoading(false);
    }
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-danger"
        onClick={handleGeneratePdf}
        disabled={loading}
      >
        {loading ? "Generating PDF..." : "LQ COMIC PDF"}
      </button>
    </div>
  );
};

export default PdfWithQrCode;
