import axios from "axios";
import { useState, useEffect } from "react";
import {
  API_ENDPOINT,
  ASSET_NAMES,
  CUBE_ASSET_ID,
  NODE_ENDPOINT,
} from "../constants.ts";
import { getAssetHolder, getBalance, mental_af_check_tx } from "./utilities.ts";
import { toast } from "react-toastify";
import loadingGIF from "../assets/loading.gif";
import algosdk from "algosdk";
import { encode } from "uint8-to-base64";
import AssetBoxForSwap from "./asset_box_for_swap.tsx";
import React from "react";
import { useWallet } from "@txnlab/use-wallet";

export default function MentalAF() {
  const [loading, setLoading] = useState(true);
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [connectType, setWalletType] = useState("");
  const [userBalance, setUserBalance] = useState([0, 0]);
  const [afmentalAssetId, setAfmentalAssetId] = useState({
    ai: 0,
    s: "n",
  });
  const [assetHolder, setAssetHolder] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const { activeAccount, signTransactions, sendTransactions } = useWallet();

  useEffect(() => {
    const userAddressLocal = activeAccount?.address;
    const connectTypeLocal = activeAccount?.providerId;
    if (userAddressLocal && connectTypeLocal) {
      setWalletAddress(userAddressLocal);
      setWalletType(connectTypeLocal);
      setLoading(false);
    }
  }, [activeAccount]);

  useEffect(() => {
    async function checkAfMentalAssetId() {
      const res = await axios.get(`${API_ENDPOINT}/mental-af/`);
      if (res.status === 200) {
        setAfmentalAssetId(res.data);
      } else {
        setAfmentalAssetId({
          ai: 0,
          s: "n",
        });
      }
    }

    if (walletAddress !== "") {
      getBalance(walletAddress).then((res) => {
        setUserBalance(res);
      });
      checkAfMentalAssetId();
    }
  }, [walletAddress]);

  useEffect(() => {
    if (afmentalAssetId.ai !== 0) {
      getAssetHolder(afmentalAssetId.ai).then((res) => {
        setAssetHolder(res);

        setLoading(false);
      });
    }
  }, [afmentalAssetId]);

  const buttonText = () => {
    if (assetHolder === walletAddress) {
      if (afmentalAssetId.s === "n") {
        return "Upgrade";
      } else {
        return "Already Upgraded";
      }
    } else if (assetHolder !== walletAddress) {
      return "You don't own this asset";
    }
  };

  async function handleMentalAfUpgrade() {
    if (isClicked) {
      return;
    }
    if (walletAddress === "") {
      toast.error("Please connect your wallet first!");
      return;
    } else if (afmentalAssetId.ai === 0) {
      toast.error("Something went wrong while fetching the asset id!");
      return;
    } else if (afmentalAssetId.s === "y") {
      toast.error("You already have the upgraded asset!");
      return;
    } else if (assetHolder !== walletAddress) {
      toast.error("You don't own this asset!");
      return;
    }

    const has_opted_in_fc = await axios.get(
      NODE_ENDPOINT +
        "/v2/accounts/" +
        walletAddress +
        "/assets/" +
        CUBE_ASSET_ID
    );
    if (has_opted_in_fc.status !== 200) {
      toast.info(
        "Please firstly opt-in to the Force Cube asset to get rewards!"
      );
      return;
    }

    setIsClicked(true);
    const transaction = await mental_af_check_tx(
      walletAddress,
      afmentalAssetId.ai
    );

    if (!transaction) {
      toast.error("Something went wrong while creating the check transaction!");
      setIsClicked(false);
      return;
    }

    let signedTxn;
    let encodedTxn;
    try {
      signedTxn = await signTransactions([
        algosdk.encodeUnsignedTransaction(transaction),
      ]);

      signedTxn = signedTxn[0];
      encodedTxn = encode(signedTxn);

      if (signedTxn && encodedTxn) {
        toast.info("Please wait while checking your upgrade request...", {
          autoClose: 4000,
        });
        const res = await axios.post(`${API_ENDPOINT}/mental-af/`, {
          tx: encodedTxn,
        });
        if (res.status === 200) {
          toast.success("Your Marvin successfully upgraded!");
          setIsClicked(false);
          setAfmentalAssetId(res.data);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      } else {
        toast.error("Something went wrong while creating the swap!");
        setIsClicked(false);
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsClicked(false);
    }
  }

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center text-primary-green font-thin">
        <img
          className="aspect-square max-h-48"
          src={loadingGIF}
          alt="loading"
        />
        Loading...
      </div>
    );
  }

  return (
    <main className="">
      <div className=" flex flex-col w-full outline-none focus:outline-none">
        <div className="flex flex-col mx-auto text-center text-sm px-4 pt-1 max-w-xl text-gray-400/90">
          Mental AF is awarded to a holder who wins the trait competition.
          <p>
            Weekly winners will have 7 days to Upgrade. Upgrading will reward
            you with 100 Force Cubes
          </p>
          {/* <p className="text-red-500">WARNING</p>
          This upgrade will Make your Marvin immutable and he cannot upgrade or
          change his traits any more! */}
        </div>
        <div className="swapperimage">
          <AssetBoxForSwap
            asset_id={afmentalAssetId.ai}
            onNameReceived={undefined}
            onTraitsReceived={undefined}
          />
        </div>
        <p className="text-cube-white text-center text-sm px-4 pt-2 mt-2 mx-auto">
          <span className="text-slate-400">Weekly Winner: </span>

          <a
            className=""
            href={`https://www.minthol.art/algo/assets/${afmentalAssetId.ai}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {afmentalAssetId.ai}
          </a>
          <p className="text-cube-white text-center text-sm pb-2 pt-1 font-semibold mx-auto">
            {ASSET_NAMES[afmentalAssetId.ai] || "N/A"}
          </p>
        </p>

        {isClicked ? (
          <button
            className="mx-auto text-cube-white bg-cube-blue hover:bg-cube-blue/80 transition px-4 py-1 inline-flex rounded items-center font-semibold mt-1 cursor-not-allowed"
            type="button"
            disabled
          >
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
          </button>
        ) : (
          <button
            onClick={handleMentalAfUpgrade}
            disabled={buttonText() !== "Upgrade"}
            className={`mx-auto text-cube-white bg-cube-blue hover:bg-cube-blue/80 transition px-4 py-1 inline-flex rounded items-center font-semibold mt-1 ${
              buttonText() !== "Upgrade" && "opacity-50 cursor-not-allowed"
            }`}
          >
            {buttonText()}
          </button>
        )}
      </div>
    </main>
  );
}
