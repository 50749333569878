import Step from "./Step.tsx";
import { MantineProvider, Grid, rem } from "@mantine/core";
import React, { useState, useEffect, useRef } from "react";
import "./Comic.css";
import { useWallet } from "@txnlab/use-wallet";
import { getComicsFromAddress, getBalance } from "./utilities.ts";
import loadingGIF from "../assets/loading.gif";
import { SelectField } from "../components/select_field.tsx";
import AssetBoxForSwap from "../components/asset_box_for_swap.tsx";
import TraitBox from "./traitbox.tsx";
import Slider from "./Slider";

const Comic = () => {
  const [isBottom, setIsBottom] = useState(false);
  const lastImageRef = useRef(null);
  const [walletAddress, setWalletAddress] = useState("");
  const [userAssets, setUserAssets] = useState([]);
  const [userBalance, setUserBalance] = useState([0, 0]);
  const { activeAccount } = useWallet();
  const [loading, setLoading] = useState(true);
  const [selectedAssetIndex, setSelectedAssetIndex] = useState(null);
  const [userAssetNames, setUserAssetNames] = useState({});

  const handleAssetClick = (index) => {
    setSelectedAssetIndex(index);
  };

  const handleNameReceived = (name, index) => {
    setUserAssetNames((prevNames) => ({
      ...prevNames,
      [index]: name,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userAddressLocal = activeAccount?.address;
        const connectTypeLocal = activeAccount?.providerId;

        if (userAddressLocal && connectTypeLocal) {
          setWalletAddress(userAddressLocal);
        }
      } catch (error) {
        console.error("Error fetching account information:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [activeAccount]);

  useEffect(() => {
    // Checking if the walletAddress is not an empty string before making API calls
    if (walletAddress !== "") {
      // Fetching assets associated with the provided walletAddress
      getComicsFromAddress(walletAddress).then((res) => {
        // Logging the content of the response (res) to the console
        //console.log("Fetched user assets:", res);
        // Updating the state with the fetched user assets
        setUserAssets(res);
        // Fetching the balance associated with the provided walletAddress
        getBalance(walletAddress).then((res) => {
          // Updating the state with the fetched user balance
          setUserBalance(res);

          // Setting loading state to false, indicating that the data has been successfully fetched
          setLoading(false);
        });
      });
    }
  }, [walletAddress]);

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center text-primary-green font-thin">
        <img
          className="aspect-square max-h-48"
          src={loadingGIF}
          alt="loading"
        />
        Loading...
      </div>
    );
  }

  return (
    <MantineProvider>
      <div className="split-screen">
        <div className="left-section">
          <h2>MY COMICS</h2>
          <div>
            <Grid
              justify="flex-start"
              align="top"
              className="comicshelf"
              gutter={{ base: 3, xs: 3, md: 3, xl: 3 }}
            >
              {userAssets.map((asset_id, index) => (
                <Grid.Col
                  span={6}
                  key={index}
                  className={`${
                    selectedAssetIndex === index ? "selected" : ""
                  }`}
                  onClick={() => handleAssetClick(index)}
                >
                  <AssetBoxForSwap
                    asset_id={asset_id}
                    onNameReceived={(name) => handleNameReceived(name, index)}
                  />
                  {userAssetNames[index] && <p>{userAssetNames[index]}</p>}
                </Grid.Col>
              ))}
            </Grid>
          </div>
        </div>
        <div className="middle-section">
          {/* Middle Content */}
          <Slider asset_id={userAssets[selectedAssetIndex]} />
        </div>
        <div className="right-section">
          <h2>REWARDS</h2>
          {/* Render TraitBox only when an asset is selected */}
          {selectedAssetIndex !== null ? (
            <TraitBox asset_id={userAssets[selectedAssetIndex]} />
          ) : (
            <p>Pick a comic to see your rewards</p>
          )}
        </div>
      </div>
    </MantineProvider>
  );
};

export default Comic;
