// Import necessary libraries and components
import { useWallet } from "@txnlab/use-wallet";
import axios from "axios";
import { useState, useEffect } from "react";
import {
  API_ENDPOINT,
  CUBE_BUY_FEE,
  CUBE_BUY_AMOUNT,
  CREATOR_WALLET,
  ALGO_EXPLORER_URL,
} from "../constants.ts";
import { cube_buy_request_txs, getBalance } from "./utilities.ts";
import { toast } from "react-toastify";
import Loader from "./Loader.js";
import { encode } from "uint8-to-base64";
import algo_logo from "../assets/algo.webp";
import cube_icon from "../assets/cube.webp";
import React from "react";
import algosdk from "algosdk";

// Main component function
export default function ForceCube() {
  // State variables
  const [loading, setLoading] = useState(true);
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [connectType, setWalletType] = useState("");
  const [userBalance, setUserBalance] = useState([0, 0]);
  const [amount, setAmount] = useState(0);
  const [isBuyClicked, setIsBuyClicked] = useState(false);
  const [buyTxId, setBuyTxId] = useState("");
  const { activeAccount, signTransactions, sendTransactions } = useWallet();

  // useEffect to load user's wallet and connection type from localStorage
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userAddressLocal = activeAccount?.address;
        const connectTypeLocal = activeAccount?.providerId;

        if (userAddressLocal && connectTypeLocal) {
          setWalletAddress(userAddressLocal);
          setWalletType(connectTypeLocal);
        }
      } catch (error) {
        console.error("Error fetching account information:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [activeAccount]);

  // useEffect to fetch user's balance
  useEffect(() => {
    if (walletAddress !== "") {
      getBalance(walletAddress).then((res) => {
        setUserBalance(res);
        setLoading(false);
      });
    }
  }, [walletAddress]);

  // Function to handle Cube purchase
  async function handleBuyCube() {
    if (!walletAddress) {
      toast.info("Please connect your wallet first!");
      return;
    }
    if (amount === 0) {
      toast.info("You need to buy at least " + CUBE_BUY_AMOUNT + "FC!");
      setIsBuyClicked(false);
      return;
    }
    setIsBuyClicked(true);

    try {
      // Get Cube purchase transactions
      const transactions = await cube_buy_request_txs(walletAddress, amount);
      if (!transactions) {
        toast.info("Something went wrong while creating the transactions!");
        setIsBuyClicked(false);
        return;
      }

      // Check if user has enough balance
      if (userBalance[0] < amount * (CUBE_BUY_FEE / CUBE_BUY_AMOUNT)) {
        toast.info("You don't have enough ALGO!");
        setIsBuyClicked(false);
        return;
      }

      // Initialize variables for signed transactions
      let user_cube_fee_tx; // 0
      let cube_transfer_tx; // 1

      // Sign transactions using useWallet module
      const signedTransactions = await signTransactions([
        algosdk.encodeUnsignedTransaction(transactions[0]),
        algosdk.encodeUnsignedTransaction(transactions[1]),
      ]);

      // Extract signed transactions
      user_cube_fee_tx = encode(signedTransactions[0]);
      cube_transfer_tx = encode(transactions[1].toByte());
      setBuyTxId("");
      console.log(user_cube_fee_tx, cube_transfer_tx);
      // Send the signed transactions to the server
      const res = await axios.post(`${API_ENDPOINT}/buy-fc/`, {
        user_cube_fee_tx,
        cube_transfer_tx,
      });

      // Handle server response
      if (res.status === 200) {
        toast.success("Successfully bought FC!");
        setIsBuyClicked(false);
        setBuyTxId(transactions[1].txID().toString());
        setAmount(0);
      } else {
        toast.error("Something went wrong while buying!");
        setIsBuyClicked(false);
      }
    } catch (error) {
      // Handle errors
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsBuyClicked(false);
    }
  }

  // Function to increase the purchase amount
  const increase = (e: any) => {
    e.preventDefault();
    setAmount(amount + CUBE_BUY_AMOUNT);
  };

  // Function to decrease the purchase amount
  const decrease = (e: any) => {
    e.preventDefault();
    if (amount > 0) {
      setAmount(amount - CUBE_BUY_AMOUNT);
    }
  };

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div className="weeklyfarm">
      {/* buying cubes */}

      <h3>GET CUBES</h3>
      <div className="buycointainer">
        <div className="buyicons">
          <div className="">
            <span className="">{CUBE_BUY_AMOUNT}</span>
            <img className="coin" src={cube_icon} alt="cube-icon" />
          </div>
          <span className="">=</span>
          <div className="">
            <span className="">{CUBE_BUY_FEE}</span>
            <img className="coin" src={algo_logo} alt="algo-logo" />
          </div>
        </div>
        <div className="buypricepicker">
          <button
            onClick={(e) => decrease(e)}
            type="button"
            className="btn btn-secondary"
          >
            −
          </button>
          <input className="form-control mb-2" value={amount} disabled></input>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={(e) => increase(e)}
          >
            +
          </button>
        </div>
        <div className="amounttext">
          {amount > 0 ? (
            <p className="text-primary-white/90 text-center text-sm px-4 mt-2">
              You will get {amount} FC for{" "}
              {amount * (CUBE_BUY_FEE / CUBE_BUY_AMOUNT)}A
            </p>
          ) : (
            <p className="text-primary-white/90 text-center text-sm px-4 mt-2">
              Click on + to set amount.
            </p>
          )}
        </div>
        <div className="buycubesbutton">
          {isBuyClicked ? (
            <button type="button" className="btn btn-info btn-lg">
              Sign Trx
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-info btn-lg"
              onClick={handleBuyCube}
            >
              GET CUBES
            </button>
          )}
        </div>
        {buyTxId !== "" && (
          <div className="">
            <span className="">CUBES ACQUIRED!</span>
            <p className="text-primary-white">
              Tx ID:{" "}
              <a
                href={`${ALGO_EXPLORER_URL}/tx/${buyTxId}`}
                target="_blank"
                rel="noreferrer"
                className=""
              >
                {buyTxId.substring(0, 5)}...
                {buyTxId.substring(buyTxId.length - 5, buyTxId.length)}
              </a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
