import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Footer.css";
import { Link } from "react-router-dom";
import { RxDoubleArrowUp } from "react-icons/rx";
import { FaXTwitter } from "react-icons/fa6";
import { TbBrandDiscord } from "react-icons/tb";
import { BsInstagram } from "react-icons/bs";
import { SiTiktok } from "react-icons/si";

const Footer = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleOffcanvas = () => setShowOffcanvas(!showOffcanvas);
  const [touchStartY, setTouchStartY] = useState(null);
  const [touchEndY, setTouchEndY] = useState(null);

  const handleTouchStart = (e) => {
    setTouchStartY(e.touches[0].clientY);
  };

  const handleTouchEnd = (e) => {
    setTouchEndY(e.changedTouches[0].clientY);

    const swipeDistance = touchStartY - touchEndY;
    if (swipeDistance > 50) {
      // Adjust this value as needed
      handleOffcanvas();
    }
  };

  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <div className="footercontainer">
        {" "}
        <div
          className="infoclick"
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onClick={handleOffcanvas}
        >
          INFO
          <RxDoubleArrowUp className="blinkingarrow" /> SOCIALS
        </div>
      </div>
      <Offcanvas
        show={showOffcanvas}
        onHide={handleOffcanvas}
        placement="bottom"
        className="footercanvas"
      >
        <Offcanvas.Header closeButton>
          {/*  <Offcanvas.Title>INFO AND LINKS</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="infocontainer">
            <div className="general">
              <Link to="/about" onClick={handleOffcanvas}>
                <h5>About</h5>
              </Link>
              <Link to="/contact" onClick={handleOffcanvas}>
                <h5>Contact</h5>
              </Link>

              {/* <h5>Services</h5> */}
              <Link to="/terms" onClick={handleOffcanvas}>
                <h5>Terms and Conditions </h5>
              </Link>
            </div>
            <div>
              <h6>Social Links</h6>
              <div className="socials">
                <a href="https://x.com/MaarsComics" target="blank">
                  <FaXTwitter className="socialimg" />
                </a>

                <a href="https://discord.gg/DvXzSBX7bN" target="blank">
                  <TbBrandDiscord className="socialimg" />
                </a>
                <a
                  href="https://www.instagram.com/studio_maars/"
                  target="blank"
                >
                  <BsInstagram className="socialimg" />
                </a>
                <a href="https://www.tiktok.com/@studio_maars" target="blank">
                  <SiTiktok className="socialimg" />
                </a>
              </div>
            </div>
            {/* <div>
              <h6>Secondary Marketplaces and Aggregators</h6>
              <div className="socials">
                <a href="https://www.minthol.art/?search=maars" target="blank">
                  <img
                    src="/minthol.jpg"
                    alt="minthol.art"
                    className="socialimg"
                  />
                </a>
                <a
                  href="https://www.asalytic.app/collections?search=maars"
                  target="blank"
                >
                  <img
                    src="/asalytic.png"
                    alt="asalytic.app"
                    className="socialimg"
                  />
                </a>
                <a href="https://xrp.cafe/collection/badripley" target="blank">
                  <img
                    src="/asalytic.png"
                    alt="asalytic.app"
                    className="socialimg"
                  />
                </a>
              </div>
            </div> */}
          </div>
          <div className="imeobrta">
            <div className="footerlogo">
              <img src="/logosm.png"></img>
            </div>
            Studio Maars, trade for digital tehnologies, design and art{" "}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Footer;
