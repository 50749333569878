// Import necessary libraries and components
import algosdk from "algosdk";
import { useWallet } from "@txnlab/use-wallet";
import axios from "axios";
import { useState, useEffect } from "react";
import { API_ENDPOINT } from "../constants.ts";
import { token_claim_request_tx } from "./utilities.ts";
import { toast } from "react-toastify";
import { encode } from "uint8-to-base64";
import React from "react";
import "./custom.scss";
import Button from "react-bootstrap/Button";

// Main component function
export default function ForceCube({ balance }: { balance: any[] }) {
  // State variables
  const [loading, setLoading] = useState(true);
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [connectType, setWalletType] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const { activeAccount, signTransactions } = useWallet();
  const [cubeRemaining, setCubeRemaining] = useState<string>("");

  // useEffect to load user's wallet and connection type from localStorage
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userAddressLocal = activeAccount?.address;
        const connectTypeLocal = activeAccount?.providerId;

        if (userAddressLocal && connectTypeLocal) {
          setWalletAddress(userAddressLocal);
          setWalletType(connectTypeLocal);
          // send to api to change cubes
          const checkcubes = await axios.post(`${API_ENDPOINT}/checkcubes/`, {
            wallet: userAddressLocal,
          });

          setCubeRemaining(checkcubes.data);
        }
      } catch (error) {
        console.error("Error fetching account information:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [activeAccount]);

  // Function to handle token claim
  async function handleClaim() {
    if (!walletAddress) {
      toast.error("Please connect your wallet first!");
      return;
    }
    setIsClicked(true);
    const transaction = await token_claim_request_tx(walletAddress);
    if (!transaction) {
      toast.error("Something went wrong while creating the swap request!");
      setIsClicked(false);
      return;
    }

    try {
      // Sign transaction using useWallet module
      const signedTxn = await signTransactions([
        algosdk.encodeUnsignedTransaction(transaction),
      ]);

      // Extract signed transaction and encode
      const encodedTxn = encode(signedTxn[0]);

      // Send the signed transaction to the server
      const res = await axios.post(`${API_ENDPOINT}/claim/`, {
        tx: encodedTxn,
      });

      // Handle server response
      if (res.status === 200) {
        toast.success(res.data);
        setIsClicked(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else if (res.status === 201) {
        toast.info(res.data);
        setIsClicked(false);
      } else {
        toast.error("Something went wrong while claiming!");
        setIsClicked(false);
      }
    } catch (error: any) {
      // Handle errors
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsClicked(false);
    }
  }

  return (
    <div className="claimer">
      {isClicked ? (
        <button className="btn btn-dark btn-l" type="button" disabled>
          <div className="spinner-border text-light mr-2" role="status"></div>
        </button>
      ) : (
        <Button className="btn btn-dark btn-sm" onClick={handleClaim}>
          {cubeRemaining ===
          "You already claimed your Force Cubes! Please wait for the next week!" ? (
            <>
              <img src="/cube.webp" alt="CubeIcon" width="70" height="70" />
              <h5>0</h5>
            </>
          ) : (
            <>
              <img src="/FCani.webp" alt="CubeIcon" width="70" height="70" />
              <h5>{cubeRemaining.match(/\d+/)}</h5>
            </>
          )}
        </Button>
      )}
    </div>
  );
}
