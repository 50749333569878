import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Styles.css";
import { Comic1, Comic2 } from "../constants.ts";
import { Pagination, Navigation } from "swiper/modules";
import SwiperCore from "swiper";

export default function Slider({ asset_id }) {
  const imageFilenames = asset_id === 807799816 ? Comic1 : Comic2;
  const [isEndOfPaginationModalOpen, setIsEndOfPaginationModalOpen] =
    useState(false);
  const [swiperKey, setSwiperKey] = useState(0);
  SwiperCore.use([Navigation, Pagination]);

  useEffect(() => {
    // Reset the key when asset_id changes to go back to the first slide
    setSwiperKey((prevKey) => prevKey + 1);
  }, [asset_id]);

  const handleReachEnd = () => {
    setIsEndOfPaginationModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEndOfPaginationModalOpen(false);
  };

  return (
    <>
      <Swiper
        key={swiperKey}
        pagination={{
          type: "progressbar",
        }}
        navigation
        className="ComicSwiper"
        onReachEnd={handleReachEnd}
      >
        {imageFilenames.map((filename, index) => (
          <SwiperSlide key={index}>
            <img src={`/images/${filename}`} alt={`Image ${index + 1}`} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className="modal"
        tabIndex="-1"
        role="dialog"
        style={{ display: isEndOfPaginationModalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">SUCCESS</h5>
            </div>
            <div className="modal-body">
              <p>
                You've reached the end of the comic and are now able to claim
                your reward. Reading the comic for the first time will grant you
                Force Cubes!
              </p>
              <button type="button" className="btn btn-info">
                CLAIM
              </button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
