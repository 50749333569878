// Importing loadingGIF and AssetImage components, as well as necessary dependencies and utilities
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ipfsToTraits } from "./utilities.ts";
import { NODE_ENDPOINT } from "../constants.ts";
import React from "react";
import { Arc69 } from "./Arc69.ts"; // Importing the documentation
import "./traitbox.css";
import { Spinner } from "react-bootstrap";

// AssetBoxForSwap component
const TraitBox: React.FC<{
  asset_id: string;
  onTraitsReceived: (traits: any) => void;
}> = ({ asset_id, onTraitsReceived }) => {
  // State to hold the URL of the asset image, initially set to a loading GIF
  const [traits, setTraits] = useState<any>({});
  const [loading, setLoading] = useState(true);

  // Effect hook to fetch asset data when the asset_id prop changes
  useEffect(() => {
    if (!asset_id) {
      console.error("Asset ID is undefined or empty");
      return;
    }

    // Define an async function to use await
    const fetchData = async () => {
      try {
        // Sending a GET request to retrieve asset data from the specified API endpoint
        const response = await axios.get(
          `${NODE_ENDPOINT}/v2/assets/${asset_id}`
        );

        if (response.data.params.url.startsWith("ipfs://")) {
          // If the condition is met, directly set traits based on ARC69 metadata
          const arc69 = new Arc69();
          const metadata = await arc69.fetch(asset_id);
          setTraits(metadata);
        } else {
          // If the condition is not met, proceed with ipfsToTraits
          const traits = await ipfsToTraits(
            response.data.params.url,
            response.data.params.reserve
          );
          setTraits(traits);

          onTraitsReceived(traits);
        }
      } catch (err) {
        // Handling any errors during the API request (may need to add more specific error handling)
        console.error(err);
      } finally {
        // Set loading to false when the data fetch is complete or in case of an error
        setLoading(false);
      }
    };

    // Call the async function
    fetchData();
  }, [asset_id, onTraitsReceived]); // Dependency array to re-run the effect when asset_id prop changes

  // Rendering the component with a rounded container and the AssetImage component displaying the asset image
  return (
    <div className="traitcontainer">
      {/* Display the loading spinner while loading is true */}
      {loading ? (
        <Spinner animation="border" role="status"></Spinner>
      ) : (
        // Display the traits once the loading is complete
        Object.entries(traits).map(([key, value]) => (
          <div
            key={key}
            className={`traitbox ${
              key === "Special1" && value !== "none" ? "Special2" : ""
            }`}
          >
            <div className="group">{key}</div>
            <div>{String(value)}</div>
          </div>
        ))
      )}
    </div>
  );
};

// Exporting the AssetBoxForSwap component as the default export
export default TraitBox;
