// Importing loadingGIF and AssetImage components, as well as necessary dependencies and utilities
import { useState, useEffect } from "react";
import axios from "axios";
import { ipfsToTraits } from "./utilities.ts";
import { NODE_ENDPOINT } from "../constants.ts";
import React from "react";
import { Arc69 } from "./Arc69.ts"; // Importing the documentation
import { Spinner } from "react-bootstrap";
import "./printbox.css";
import QRCode from "qrcode.react";

const PrintBox: React.FC<{ asset_id: string }> = (props) => {
  // State to hold the URL of the asset image, initially set to a loading GIF
  const [traits, setTraits] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [unitName, setUnitName] = useState<any>("");
  const [name, setName] = useState<any>("");
  const qrCodeUrl = `https://allo.info/asset/${props.asset_id}`;
  // Effect hook to fetch asset data when the asset_id prop changes
  useEffect(() => {
    if (!props.asset_id) {
      console.error("Asset ID is undefined or empty");
      return;
    }
    // Define an async function to use await
    const fetchData = async () => {
      try {
        // Sending a GET request to retrieve asset data from the specified API endpoint
        const response = await axios.get(
          `${NODE_ENDPOINT}/v2/assets/${props.asset_id}`
        );
        /* console.log("metadata", response); */
        if (response.data.params.url.startsWith("ipfs://")) {
          // If the condition is met, directly set traits based on ARC69 metadata
          const arc69 = new Arc69();
          const metadata = await arc69.fetch(props.asset_id);

          setTraits(metadata);
        } else {
          // If the condition is not met, proceed with ipfsToTraits
          const traits = await ipfsToTraits(
            response.data.params.url,
            response.data.params.reserve
          );
          const unitname = response.data.params["unit-name"];
          const name = response.data.params.name;

          //console.log("metadata2", traits);
          // Setting the URL in the state
          setUnitName(unitname);
          setTraits(traits);
          setName(name);
        }
      } catch (err) {
        // Handling any errors during the API request (may need to add more specific error handling)
        console.error(err);
      } finally {
        // Set loading to false when the data fetch is complete or in case of an error
        setLoading(false);
      }
    };

    // Call the async function
    fetchData();
  }, [props.asset_id]); // Dependency array to re-run the effect when asset_id prop changes

  // Rendering the component with a rounded container and the AssetImage component displaying the asset image
  return (
    <div className="printboxcontainer">
      <div className="tophalf">
        <div className="unitname">
          <h3>{name}</h3>
          <p>COLLECTION: MENTAL MARVIN</p>
          <p>BLOCKCHAIN: ALGORAND </p>
        </div>
        <div className="unitdata">
          <p>ASA ID: {props.asset_id}</p>
          <p>UNIT - {unitName}</p>
          <p>NO. {unitName.substring(4)}/3600 </p>
        </div>
      </div>
      <div className="bottomhalf">
        <div className="bigtraitcontainer">
          {/* Display the loading spinner while loading is true */}
          {loading ? (
            <Spinner animation="border" role="status"></Spinner>
          ) : (
            // Display the traits once the loading is complete
            Object.entries(traits).map(([key, value]) => (
              <div key={key} className="bigtraitbox">
                <div className="biggroup">{key}</div>
                <span>-</span>
                <div>{String(value)}</div>
              </div>
            ))
          )}
        </div>
        <div className="QRCodeContainer">
          <a href={qrCodeUrl}></a>
          <QRCode value={qrCodeUrl} size={400} />
        </div>
      </div>
    </div>
  );
};

export default PrintBox;
