import loadingGIF from "../assets/loading.gif";
import AssetImage from "./asset_image.tsx";
import { useState, useEffect } from "react";
import axios from "axios";
import { ipfsToUrl } from "./utilities.ts";
import { NODE_ENDPOINT } from "../constants.ts";
import React from "react";

const MarvinBox = ({ asset_id, onImageUrlChange }) => {
  const [url, setUrl] = useState<string>(loadingGIF);
  const [assetName, setAssetName] = useState<string>("");

  useEffect(() => {
    if (asset_id && asset_id !== 0) {
      axios
        .get(`${NODE_ENDPOINT}/v2/assets/${asset_id}`)
        .then((response) => {
          ipfsToUrl(
            response.data.params.url,
            response.data.params.reserve
          ).then((url) => {
            setUrl(url);
            setAssetName(response.data.params.name);
            // Call the callback function with the image URL
            onImageUrlChange(url);
          });
        })
        .catch((err) => {});
    }
  }, [asset_id, onImageUrlChange]);

  return (
    <div>
      <div>
        <AssetImage image_url={url} />
        <p>{assetName}</p>
      </div>
    </div>
  );
};

export default MarvinBox;
