import React, { useState, useEffect, useMemo, useRef } from "react";

import "./Mymarvins.css";
import { useWallet } from "@txnlab/use-wallet";
import { getAssetsFromAddress, fetchAssetData } from "./utilities.ts";
import MarvinBox from "./marvin_box.tsx";
import TraitBox from "./traitbox.tsx";
import { ASSET_NAMES } from "../constants.ts";
import html2canvas from "html2canvas";
import { Pagination, Navigation } from "swiper/modules";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import PrintBox from "./printbox.tsx";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import marvin2 from "../assets/marvin/marvin2.webp";
import AssetBox from "./assetboxoptimal.tsx";

const Mymarvins = () => {
  const [printAfVisible, setPrintAfVisible] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [userAssets, setUserAssets] = useState([]);
  const { activeAccount } = useWallet();
  const [loading, setLoading] = useState(true);
  const [selectedAssetIndex, setSelectedAssetIndex] = useState(null);
  const [paging, setPaging] = useState(1);
  const PAGE_SIZE = 20;
  const [searchFilter, setSearchFilter] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");
  SwiperCore.use([Navigation, Pagination]);
  const [swiper, setSwiper] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [buttonFilter, setButtonFilter] = useState({ key: "", value: "" });
  const [traits, setTraits] = useState({});
  const [loadingData, setLoadingData] = useState(true);
  const [fetchedAssets, setFetchedAssets] = useState(new Set());
  const [imageUrls, setImageUrls] = useState({});

  const handleAssetClick = (index) => {
    const absoluteIndex = (paging - 1) * PAGE_SIZE + index;
    setSelectedAssetIndex(absoluteIndex);
    // If Swiper is ready, update its active slide immediately
    if (swiper) {
      swiper.slideTo(absoluteIndex);
    }
  };

  useEffect(() => {
    const fetchAssets = async () => {
      if (walletAddress !== "") {
        const assets = await getAssetsFromAddress(walletAddress);
        setUserAssets(assets);

        // Automatically set the first asset as selected when assets are fetched
        if (assets.length > 0) {
          setSelectedAssetIndex(0);
        }

        setLoading(false);
        setLoadingData(false);
      }
    };

    fetchAssets();
  }, [walletAddress]);

  useEffect(() => {
    if (activeAccount) {
      const userAddressLocal = activeAccount?.address;
      if (userAddressLocal) {
        setWalletAddress(userAddressLocal);
      }
    }
  }, [activeAccount]);

  const handleTraitsReceived = (newTraits, assetId) => {
    setTraits((prevTraits) => ({
      ...prevTraits,
      [assetId]: newTraits,
    }));
    setFetchedAssets((prevFetchedAssets) =>
      new Set(prevFetchedAssets).add(assetId)
    );
  };

  const handleImageUrlReceived = (imageUrl, assetId) => {
    setImageUrls((prevUrls) => ({
      ...prevUrls,
      [assetId]: prevUrls[assetId] !== imageUrl ? imageUrl : prevUrls[assetId], // Avoid duplicate URLs
    }));
  };
  const filteredAssets = useMemo(() => {
    const lowerSearchFilter = searchFilter.toLowerCase();

    return userAssets.filter((assetId) => {
      const assetTraits = traits[assetId] || {};
      const assetName = ASSET_NAMES[assetId]?.toLowerCase() || "";

      // Check if the asset ID or name matches the search filter
      const matchesSearchFilter =
        assetId.toString().includes(lowerSearchFilter) ||
        assetName.includes(lowerSearchFilter);

      // Check if any trait value matches the search filter
      const matchesTraitFilter = Object.values(assetTraits).some((trait) =>
        trait.toString().toLowerCase().includes(lowerSearchFilter)
      );

      const matchesButtonFilter =
        buttonFilter.key && buttonFilter.value
          ? assetTraits[buttonFilter.key] === buttonFilter.value
          : true;

      return (
        (matchesSearchFilter || matchesTraitFilter || searchFilter === "") &&
        matchesButtonFilter
      );
    });
  }, [userAssets, traits, searchFilter, buttonFilter]);

  useEffect(() => {
    setPaging(1);
  }, [searchFilter, buttonFilter]);

  const traitOptions = [
    { key: "Special1", value: "none", label: "No Special" },
    { key: "Clothes", value: "none", label: "No Clothes" },
    { key: "Hand", value: "empty", label: "No Hands" },
    { key: "Hair", value: "none", label: "No Hair" },
  ];

  const handleTraitChange = (event) => {
    const [key, value] = event.target.value.split(":");
    setButtonFilter({ key, value });
  };

  useEffect(() => {
    // Update Swiper's active slide when the selected asset changes
    if (swiper && selectedAssetIndex !== null) {
      swiper.slideTo(selectedAssetIndex);
    }
  }, [selectedAssetIndex, swiper]);

  const handleSlideChange = (swiper) => {
    const index = swiper.activeIndex;
    const newPage = Math.floor(index / PAGE_SIZE) + 1;
    setPaging(newPage);
    setSelectedAssetIndex(index);
  };

  //canvas

  const handleDownloadImage = async () => {
    setPrintAfVisible(true);
    const rightSectionMarvin = document.querySelector(".printaf");
    const assetId = filteredAssets[selectedAssetIndex];
    const assetName = ASSET_NAMES[assetId];
    if (rightSectionMarvin) {
      setLoadingPrint(true);
      setTimeout(async () => {
        try {
          // Use html2canvas to capture the content of the right section
          const htmlCanvas = await html2canvas(rightSectionMarvin, {
            useCORS: true,
            width: 2953,
            height: 4175,
          });

          // Convert the canvas to a data URL
          const dataUrl = htmlCanvas.toDataURL("image/png");

          // Create a temporary link element
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = `${assetName}PRINT.png`;

          // Append the link to the document and click it to trigger the download
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error("Error downloading image:", error);
        } finally {
          // Ensure loading state is set to false after the download
          setLoadingPrint(false);
          setPrintAfVisible(false);
        }
      }, 2000);
    }
  };

  //Downloadmarvins
  const handleDownload = async () => {
    if (downloadUrl) {
      const assetId = filteredAssets[selectedAssetIndex];
      const assetName = ASSET_NAMES[assetId];
      setLoadingImage(true);

      // Simulate a 2-second delay before starting the download
      setTimeout(async () => {
        try {
          const response = await fetch(downloadUrl);
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = `${assetName}.png`;
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Error downloading image:", error);
        } finally {
          // Ensure loading state is set to false after the download
          setLoadingImage(false);
        }
      }, 2000);
    }
  };

  return (
    <div className="limiter">
      {userAssets.length === 0 ? (
        <div className="nomarvin-section-marvin">
          {/* ... Other UI elements when no assets */}
        </div>
      ) : (
        <div className="split-screen-marvin">
          {/* Left Section */}
          <div className="left-section-marvin-wrap">
            <h2 className="section-title section-title--mobile">
              MY COLLECTION
            </h2>
            <div className="left-section-marvin">
              <h2 className="section-title section-title--left section-title--desktop">
                MY COLLECTION
              </h2>
              <div className="left-section-top-content">
                <div className="marvinSearchWrap">
                  <select
                    id="traitFilterSelect"
                    onChange={handleTraitChange}
                    value={`${buttonFilter.key}:${buttonFilter.value}`}
                  >
                    <option value="">Show All</option>
                    {traitOptions.map((option) => (
                      <option
                        key={`${option.key}:${option.value}`}
                        value={`${option.key}:${option.value}`}
                        disabled={loadingData}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>

                  <input
                    id="searchFilterInput"
                    className="marvinsearch"
                    type="text"
                    placeholder="ID, Name or Trait"
                    value={searchFilter}
                    disabled={loadingData}
                    onChange={(e) =>
                      setSearchFilter(e.target.value.toLowerCase())
                    }
                  />
                </div>
                <div className="pagination">
                  <div className="paginationButtonsWrap">
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => {
                        if (paging > 1) {
                          setPaging(paging - 1);
                          const newIndex = (paging - 2) * PAGE_SIZE;
                          setSelectedAssetIndex(newIndex);
                        }
                      }}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => {
                        const totalPages = Math.ceil(
                          filteredAssets.length / PAGE_SIZE
                        );
                        if (paging < totalPages) {
                          setPaging(paging + 1);
                          const newIndex = paging * PAGE_SIZE;
                          setSelectedAssetIndex(newIndex);
                        }
                      }}
                    >
                      Next
                    </button>
                  </div>
                  <p>
                    {paging} / {Math.ceil(filteredAssets.length / PAGE_SIZE)}
                  </p>
                </div>
              </div>
              <div className="left-section-bottom-content">
                <div className="marvinshelf">
                  {filteredAssets
                    .slice((paging - 1) * PAGE_SIZE, paging * PAGE_SIZE)
                    .map((asset, index) => (
                      <div
                        key={asset}
                        className={`grid-item ${
                          selectedAssetIndex ===
                          (paging - 1) * PAGE_SIZE + index
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => handleAssetClick(index)}
                      >
                        <AssetBox
                          asset_id={asset}
                          onNameReceived={undefined}
                          onTraitsReceived={
                            !fetchedAssets.has(asset)
                              ? handleTraitsReceived
                              : undefined
                          }
                          onImageUrlReceived={
                            !fetchedAssets.has(asset)
                              ? handleImageUrlReceived
                              : undefined
                          }
                        />
                        <span className="marvinId">
                          {ASSET_NAMES[asset]?.substring(14)}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="right-section-marvin-wrap">
            <h2 className="section-title section-title--mobile">CLOSER LOOK</h2>
            <div className="right-section-marvin">
              <h2 className="section-title section-title--right section-title--desktop">
                CLOSER LOOK
              </h2>
              <div className="undertitle">
                <Swiper
                  className="marvinswiper"
                  lazy={{
                    loadPrevNext: true,
                    loadPrevNextAmount: 4,
                    loadOnTransitionStart: true,
                  }}
                  loop={false}
                  slidesPerView={1}
                  navigation
                  onSlideChange={handleSlideChange}
                  onSwiper={(swiper) => {
                    setSwiper(swiper);
                    if (selectedAssetIndex !== null) {
                      swiper.slideTo(selectedAssetIndex);
                    }
                  }}
                  initialSlide={selectedAssetIndex}
                  preloadImages={false}
                >
                  {filteredAssets.map((assetId, index) => (
                    <SwiperSlide key={index}>
                      <div className="imagecontainer">
                        <img
                          src={imageUrls[assetId]}
                          alt={ASSET_NAMES[assetId]}
                        />
                        <p>{ASSET_NAMES[assetId]}</p>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="details">
                  <div className="traits">
                    <h2 className="section-title section-title--mobile">
                      TRAITS
                    </h2>
                    <TraitBox asset_id={filteredAssets[selectedAssetIndex]} />
                    <p>_____</p>
                    <p>LINKS</p>
                    <a
                      href={`https://allo.info/asset/${filteredAssets[selectedAssetIndex]}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="/allo.jpg" alt="Allo Info" />
                    </a>
                    <a
                      href={`https://www.minthol.art/algo/assets/${filteredAssets[selectedAssetIndex]}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="/minthol.jpg" alt="minthol.art" />
                    </a>
                    <a
                      href={`https://www.asalytic.app/nft/${filteredAssets[selectedAssetIndex]}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="/asalytic.png" alt="Asalytic" />
                    </a>
                  </div>
                  <div className="downloads">
                    <h2 className="section-title section-title--mobile">
                      CANVAS
                    </h2>
                    <button
                      className="btn btn-info"
                      onClick={handleDownload}
                      disabled={loadingImage}
                    >
                      {loadingImage ? (
                        <div
                          className="spinner-border text-light"
                          role="status"
                        ></div>
                      ) : (
                        "Download HQ Image"
                      )}
                    </button>
                    <p>Download HQ image file.</p>
                    <p>___</p>
                    <button
                      className="btn btn-info"
                      onClick={handleDownloadImage}
                      disabled={loadingPrint}
                    >
                      {loadingPrint ? (
                        <div
                          className="spinner-border text-light"
                          role="status"
                        ></div>
                      ) : (
                        "PRINT AF"
                      )}
                    </button>
                    <p>
                      Download HQ image with all the trait and blockchain data
                      ready for print.
                    </p>
                    (large file - 15-30 MB)
                  </div>
                </div>
              </div>
            </div>
            <div
              className="printaf"
              style={{
                display: printAfVisible ? "block" : "none",
                position: "absolute",
                left: "-9999px",
                top: "-9999px",
              }}
            >
              <div className="bigimage">
                <MarvinBox
                  asset_id={filteredAssets[selectedAssetIndex]}
                  onImageUrlChange={setDownloadUrl}
                />
              </div>
              <div className="bigtraits">
                <PrintBox asset_id={filteredAssets[selectedAssetIndex]} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Mymarvins;
