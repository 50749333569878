import React, { useState, useEffect } from "react";
import { getMintStatus, getComicID } from "./utilities.ts";

const MintStatus = ({ wallet, totalAssets, refreshData }) => {
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchMintStatus = async () => {
      try {
        const result = await getMintStatus(wallet);
        setStatus(result);
      } catch (error) {
        console.error("Error fetching mint status:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMintStatus();
  }, [wallet, refreshData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (status === 0) {
    return (
      <div>
        <h6>Mint Status</h6>
        <div className="progress-container">
          <div className="progress-bar fully-minted">FULLY MINTED</div>
        </div>
      </div>
    );
  }
  const progressWidth = totalAssets ? (status / totalAssets) * 100 : 0;

  return (
    <div>
      <h6>Mint Status</h6>
      <div className="progress-container">
        <div
          className="progress-bar"
          style={{ width: `${progressWidth}%` }}
        ></div>
        <div className="progress-text">
          {status} / {totalAssets}
        </div>
      </div>
    </div>
  );
};

export default MintStatus;
