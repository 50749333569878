import React, { useEffect, useState } from "react";
import { useWallet } from "@txnlab/use-wallet";
import "./Connectdropdown.css";
import { formatAddress } from "./utilities.ts";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import { LuWallet } from "react-icons/lu";
import GetWallet from "./GetWallet";
import { Link } from "react-router-dom";

const ConnectDropdown = React.forwardRef(() => {
  const { providers, activeAccount } = useWallet();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

  const handleConnect = (provider) => {
    if (!provider.isConnected) {
      // If not connected, initiate the connection
      provider.connect();
    } else {
      // If connected, disconnect
      provider.disconnect();
    }
  };

  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <Button type="button" className="btn btn-info " onClick={handleOffcanvas}>
        <LuWallet className="responsive-icon" />
      </Button>

      <Offcanvas
        show={showOffcanvas}
        onHide={handleOffcanvas}
        placement="top"
        className="connectoffcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>WELCOME TO MENTAL MARVIN</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="asci2">
            <p>##---/--</p>
            <p> ---___//...---___//...--</p>
            <p>-//---##--__#////---</p>
            <p>
              -//---##--__#/--==%%###---/<br></br>
              --""""--&__--&--_----$&/----##"---
            </p>
          </div>
          <div className="asci4">
            //////==========//////<br></br>
            //---con//.076_//alg-//<br></br>
            ///////==========//////
          </div>
          <div className="connectdropdown">
            {/* Include the WalletConnectionMenu component */}
            <div className="connectbody">
              <h2>CONNECT</h2>
              {providers?.map((provider) => (
                <div className="connectgrid" key={provider.metadata.id}>
                  <h4 className="icon">
                    <img
                      width={30}
                      height={30}
                      alt={`${provider.metadata.name} icon`}
                      src={provider.metadata.icon}
                    />
                    {provider.metadata.name}{" "}
                    {provider.isActive && (
                      <span>{formatAddress(activeAccount?.address)}</span>
                    )}
                  </h4>
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => handleConnect(provider)}
                  >
                    {provider.isConnected ? (
                      <span>Disconnect</span>
                    ) : (
                      <span>Connect</span>
                    )}
                  </button>
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={provider.setActiveProvider}
                    disabled={!provider.isConnected || provider.isActive}
                  >
                    Activate
                  </button>
                </div>
              ))}
            </div>
            <div className="walletinfo">
              <h2>GET A WALLET</h2>

              <div className="downloadwallet">
                <GetWallet />
              </div>
            </div>
            <div className="info">
              <h2>DISCLAIMER</h2>
              <div>
                Connecting to this site you are agreeing to the general{" "}
                <Link to="/terms">Terms and Conditions</Link>
                <p>
                  After connecting all the information about the user and user
                  assets is gathered from the public ledger.
                </p>
                <p>
                  This site will never ask for your password, personal
                  information or seed phrase.
                  <br />
                  If you notice any suspicious activiy please report it
                  immediately.
                </p>
                <p>
                  Mental Marvin is an Comic book, art and utility project with
                  digital collectables. Collecting various collectables makes
                  you cool but there is no promise of return of investment. Just
                  have fun and get #mental{" "}
                </p>
              </div>
              <div></div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
});

ConnectDropdown.displayName = "ConnectDropdown";
export default ConnectDropdown;
