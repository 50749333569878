import React, { useState, useEffect, useCallback } from "react";
import "./reader.css";
import { Link } from "react-router-dom";
import { useWallet } from "@txnlab/use-wallet";
import { getUserComics } from "./utilities.ts";
import { IoIosArrowUp } from "react-icons/io";

// Import all the comic images at once using require.context
const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

// Adjust the path to your images folder inside your 'src' or 'public' directory
const images = importAll(
  require.context("../assets/comic/EP1", false, /\.(png|jpe?g|svg)$/)
);

const ComicReader = () => {
  const [page, setPage] = useState(1);
  const [imageSrcs, setImageSrcs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [walletAddress, setWalletAddress] = useState("");
  const { activeAccount } = useWallet();
  const [comicCount, setComicCount] = useState([]);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const totalPages = Object.keys(images).length;
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const userAddressLocal = activeAccount?.address;

        if (userAddressLocal) {
          setWalletAddress(userAddressLocal);
          const comicsRes = await getUserComics(userAddressLocal);
          setComicCount(comicsRes || []);
        } else {
          // Set comicCount to an empty array if no wallet address is present
          setComicCount([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [activeAccount]);

  // Determine the maximum number of images to display
  const maxAccessiblePage =
    walletAddress === ""
      ? 8
      : Array.isArray(comicCount) && comicCount.some((count) => count > 0)
      ? totalPages
      : 8;
  // Load initial images
  useEffect(() => {
    const allImages = [];
    for (let i = 1; i <= totalPages; i++) {
      const image = images[`page${i}.jpg`];
      if (image) {
        allImages.push(image);
      }
    }
    setImageSrcs(allImages);
  }, [totalPages]);

  // Handle scroll event to load more images and toggle button visibility
  const handleScroll = useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    // Toggle visibility of the "Back to Top" button
    if (scrollTop > 300) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }

    // Load more images when scrolling near the bottom
    if (scrollTop + windowHeight >= documentHeight - 100 && !loading) {
      setLoading(true);
      // Set page to the next set of images
      setPage((prevPage) => Math.min(prevPage + 1, maxAccessiblePage));
      setLoading(false);
    }
  }, [loading, maxAccessiblePage]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  // Limit the number of images displayed
  const displayImages = imageSrcs.slice(0, maxAccessiblePage);

  const isNextDisabled = page >= maxAccessiblePage;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="readerwrapper">
      <div className="backtokiosk">
        <Link to="/kiosk" type="button" className="btn btn-info">
          BACK TO KIOSK
        </Link>
      </div>
      <div className="closeposition">
        <Link
          to="/"
          type="button"
          className="btn-close"
          aria-label="Close"
        ></Link>
      </div>
      <div className="webreader">
        <div className="pages">
          {displayImages.length > 0 ? (
            displayImages.map((src, index) => (
              <img key={index} src={src} alt={`Comic Page ${index + 1}`} />
            ))
          ) : (
            <p>No images available</p>
          )}
        </div>
        {isNextDisabled && (
          <div className="webnocomic">
            To continue enjoying the Mental Marvin story you will have to buy a
            digital comic.
          </div>
        )}
      </div>
      {showBackToTop && (
        <div className="backtotop">
          <button className="btn btn-secondary" onClick={scrollToTop}>
            <IoIosArrowUp />
          </button>
        </div>
      )}
    </div>
  );
};

export default ComicReader;
