import React from "react";
import loadingGIF from "../assets/loading.gif";

type Image = {
  image_url: string;
};

export default function AssetImage(props: Image) {
  return (
    <div>
      {props.image_url === loadingGIF ? (
        <div className="spinner-border text-light" role="status"></div>
      ) : (
        <img loading="lazy" src={props.image_url} alt={props.image_url} />
      )}
    </div>
  );
}
