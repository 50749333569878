import React from "react";
import { ASSET_NAMES } from "../constants.ts";

type SelectFieldProps = {
  text: string;
  selectedAsset: string;
  setSelectedAsset: (asset: string) => void;
  assets: number[];
};

export const SelectField = (props: SelectFieldProps) => {
  return (
    <div>
      <label className="selectfield">Select {props.text}</label>
      <select
        id="asset"
        name="asset"
        className="form-select form-select-lg mb-1"
        aria-label="Default select example"
        value={props.selectedAsset}
        onChange={(e) => props.setSelectedAsset(e.target.value)}
      >
        <option value=""></option>
        {props.assets && (
          <>
            {props.assets.map((asset) => {
              // Extract numbers from ASSET_NAMES[asset] using regular expression
              const assetName = ASSET_NAMES[asset];
              const numbersOnly = assetName.match(/\d+/g); // Regular expression to match numbers

              return (
                <option key={asset} value={asset}>
                  {" "}
                  #{numbersOnly ? numbersOnly.join(" ") : ""}{" "}
                  {/* Display numbers only */}
                </option>
              );
            })}
          </>
        )}
      </select>
    </div>
  );
};
