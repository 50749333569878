import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import MentalAF from "./mental_af.tsx";

const Home = () => {
  return (
    <div className="mentalaf">
      <div id="home-container">
        <div className="limiter">
          <div id="left-container">
            <h2>MENTAL AF</h2>
            <p>The ultimate stage for the mentalest of holders! </p>
            <MentalAF />
            <div className="closeposition">
              <Link
                to="/"
                type="button"
                className="btn-close"
                aria-label="Close"
              ></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
