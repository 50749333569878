import { useEffect, useState } from "react";
import axios from "axios";
import { API_ENDPOINT, IPFS_PREFIX } from "../constants.ts";
import { toast } from "react-toastify";
import React from "react";
import { Spinner } from "react-bootstrap";
import "./specialbenefits.css";

export type SpecialTraitBenefitsProps = {
  token_id: number;
  token_name: string;
  description?: string;
  trait_name: string;
  trait_image: string;
  token_image: string;
  external_link?: string;
};

export default function SpecialTraitBenefits() {
  const [loading, setLoading] = useState(true);
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [specialTraitBenefits, setSpecialTraitBenefits] = useState<
    SpecialTraitBenefitsProps[]
  >([]);

  useEffect(() => {
    async function getSpecialTraitBenefits() {
      const response = await axios.get(
        `${API_ENDPOINT}/special_trait_benefits/`
      );
      /* console.log("response", response); */
      setSpecialTraitBenefits(response.data);
    }
    setLoading(false);
    getSpecialTraitBenefits();
  }, [walletAddress]);

  if (loading) {
    return (
      <div className="loader">
        <Spinner />
      </div>
    );
  }

  return (
    <main className="specialscont">
      <div className="specialsmain">
        <p>
          Having a special trait on your Marvin can bring you some benefits like
          recieving utility tokens, extra cubes or one time airdrops. <br></br>{" "}
          Token drops are limited, please check the treasury indicator.
        </p>
        <div className="specialsgrid">
          <h2>SPECIAL TRAITS</h2>

          {specialTraitBenefits.map((item, index) => (
            <div className="specialtraitbox" key={index}>
              <h3>{item.trait_name.toUpperCase()}</h3>
              <div className="px-2 mb-2">
                <img
                  className="specialtraitimage"
                  src={IPFS_PREFIX + "/" + item.trait_image}
                  alt={`${item.trait_name}_image`}
                />
                <h4>BENEFIT</h4>
                <div className="stokenframe">
                  <img
                    className="specialtraittoken"
                    src={IPFS_PREFIX + "/" + item.token_image}
                    alt={`${item.token_name}_image`}
                  />
                  <h5 className="text-lg text-neutral-100 border-r-2 border-neutral-600 pr-2">
                    {item.token_name}
                  </h5>
                  <h6 className="text-neutral-100 ml-1">{item.token_id}</h6>
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={() => {
                      navigator.clipboard.writeText(item.token_id.toString());
                      toast.info("Copied to clipboard", {
                        position: "bottom-right",
                        autoClose: 500,
                        hideProgressBar: true,
                      });
                    }}
                  >
                    COPY
                  </button>
                </div>
                {item.description && (
                  <p className="specialdescription">{item.description}</p>
                )}
                {item.external_link && (
                  <a
                    className="btn btn-info"
                    href={item.external_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Find out more
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
}
