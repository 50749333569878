// Importing loadingGIF and AssetImage components, as well as necessary dependencies and utilities

import axios from "axios";
import { ipfsToTraits } from "./utilities.ts";
import { NODE_ENDPOINT } from "../constants.ts";

import { Arc69 } from "./Arc69.ts"; // Importing the documentation
import "./traitbox.css";
import { getAssetsFromAddress } from "./utilities.ts";
import { API_ENDPOINT } from "../constants.ts";

export async function special_data(wallet: string) {
  const userAssets = await getAssetsFromAddress(wallet);

  const fetchedTraits: any[] = [];
  // Fetch special trait benefits from the API
  const specialTraitBenefitsResponse = await axios.get(
    `${API_ENDPOINT}/special_trait_benefits/`
  );
  const specialTraitBenefits = specialTraitBenefitsResponse.data;

  // Iterate over each asset ID in the userAssets array
  for (const assetId of userAssets) {
    try {
      // Sending a GET request to retrieve asset data from the specified API endpoint
      const response = await axios.get(`${NODE_ENDPOINT}/v2/assets/${assetId}`);

      let traits: any;

      if (response.data.params.url.startsWith("ipfs://")) {
        // If the condition is met, directly set traits based on ARC69 metadata
        const arc69 = new Arc69();
        const metadata = await arc69.fetch(assetId);
        traits = metadata;
      } else {
        // If the condition is not met, proceed with ipfsToTraits
        traits = await ipfsToTraits(
          response.data.params.url,
          response.data.params.reserve
        );
      }

      fetchedTraits.push({ assetId, traits });
    } catch (err) {
      // Handling any errors during the API request (may need to add more specific error handling)
      console.error(`Error fetching traits for asset ID ${assetId}:`, err);
    }
  }

  const matchingBenefits = specialTraitBenefits.filter((benefit: any) => {
    return fetchedTraits.some((asset: any) => {
      if (asset.traits) {
        // Extract trait values from the asset.traits object
        const traitValues = Object.values(asset.traits);
        // Check if any trait value matches the benefit trait_name
        return traitValues.some((trait: any) => trait === benefit.trait_name);
      }
      return false; // If asset.traits is not defined or null
    });
  });

  // Return the fetched traits

  return matchingBenefits;
}
