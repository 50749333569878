import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div class="cube-container">
      <div class="cube">
        <div class="face front">FORCE CUBE</div>
        <div class="face back">FORCE CUBE</div>
        <div class="face right">FORCE CUBE</div>
        <div class="face left">FORCE CUBE</div>
        <div class="face top">FORCE CUBE</div>
        <div class="face bottom">FORCE CUBE</div>
      </div>
    </div>
  );
};

export default Loader;
